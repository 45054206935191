<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="part1 flex flex-center">
        <div class="left">
          <template>
            <div v-for="(item, index) in categoryList">
              <router-link class="flex-box mb5"  :to="{path: '/productList?category_id='+item.id}">
                <img class="icon" :src="item.image_text" alt="" />
                <div class="fs18 flex-grow-1 m-ellipsis title pointer">
                  {{ item.name }}
                </div>
              </router-link>
              <div class="mb20 flex flex-warp">
                <router-link
                  class="fs14 col3 lh30 mr10 pointer plans"
                  v-for="(item1, index1) in item.children"

                  :to="{ path: '/productList?second_id='+item1.id }"
                  :key="index1"
                >
                  {{ item1.name }}
                </router-link>
              </div>
            </div>
          </template>

          <router-link class="btn fs16 fwb pointer" :to="{ path: '/category' }">
            查看全部分类
          </router-link>
        </div>

        <swiper class="showImg" :options="swiperOption" ref="mySwiper">
          <swiper-slide
            class="swiper-slide pointer"
            v-for="item in banner"
            :key="item.id"
          >
            <img
              :key="item.id"

              :src="item.image_text"
              alt="暂无图片"
              @click="gogoods(item.goods_id,item.id)"
              v-show="item.id == currentIndex"
          /></swiper-slide>

    </swiper>

      </div>
      <div class="hots_part pt50 pb30 bg-main">
        <img class="customer pointer" src="../assets/images/customer.png" alt="" />
        <div class="flex-box mb40 mb35">
          <img
            class="index_icon4"
            src="../assets/images/index_icon4.png"
            alt=""
          />
          <div class="fs30 col3 fwb lh42">热销商品</div>
        </div>
        <div class="flex  flex-wrap">
          <router-link v-for="(item, index) in hotList"   :to="{ path: '/productDetail?id=' + item.id }"     :key="index" class="product pointer">
            <img class="img" :src="item.image_text" alt="" />
            <div class="main">
              <div class="m-ellipsis fs16 col3 fwb lh22 mb5">{{item.goods_name}}</div>
              <div class="fs12 col9 lh18 mb5">{{item.goods_name}}</div>
              <div class="flex">
                <div class="flex-grow-1">
                  <div class="lh34 mb3">
                    <span class="fs24 red fwb pr10">¥{{item.price}}</span
                    ><span class="fs16 col9 mb3">¥{{item.origin_price}}</span>
                  </div>
                  <div class="fs12 col9 lh17">已售:{{item.total_sales}}</div>
                </div>
                <img
                  class="cart_icon pointer"
                  src="../assets/images/addCart.png"
                  alt=""
                />
              </div>
            </div>
          </router-link>
        </div>
        <router-link class="flex-box flex-center col6 fs14 btn pointer" :to="{ path: '/productList?type=1' }" >
          <div>查看全部</div>
          <img class="arrow" src="../assets/images/arrow_right2.png" alt="" />
        </router-link>
      </div>
      <div class="new_part pt50 pb30 bg-main">
        <div class="flex-box mb40 mb35">
          <img
            class="index_icon4"
            src="../assets/images/index_icon5.png"
            alt=""
          />
          <router-link class="fs30 colf fwb lh42" :to="{ path: '/productList?type=1' }">最新商品</router-link>
        </div>
        <div class="flex  flex-wrap">
          <router-link
            v-for="(item, index) in newList"

            :to="{ path: '/productDetail?id=' + item.id }"
            :key="index"
            class="product pointer"
          >
            <img class="img" :src="item.image_text" alt="" />
            <div class="main">
              <div class="m-ellipsis fs16 col3 fwb lh22 mb5">{{item.goods_name}}</div>
              <div class="fs12 col9 lh18 mb5">{{item.desc}}</div>
              <div class="flex">
                <div class="flex-grow-1">
                  <div class="lh34 mb3">
                    <span class="fs24 red fwb pr10">¥{{item.price}}</span>
                    <span class="fs16 col9 mb3">¥{{item.origin_price}}</span>
                  </div>
                  <div class="fs12 col9 lh17">已售:{{item.total_sales}}</div>
                </div>
                <img
                  class="cart_icon pointer"
                  src="../assets/images/addCart.png"
                  alt=""
                />
              </div>
            </div>
          </router-link>
        </div>
        <router-link class="flex-box flex-center colf fs14 btn pointer" :to="{ path: '/productList?type=1' }" >
          <div>查看全部</div>
          <img class="arrow" src="../assets/images/arrow_right1.png" alt="" />
        </router-link>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
  <script>
import { apiGetIndex ,apiGetIsgo} from "../request/api";
import {  swiper, swiperSlide  } from "../../node_modules/vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      list: [],
      //轮播图
      currentIndex: 0, //当前所在图片下标
      timer: null, //定时轮询
      imgArr: [
        { id: 0, url: require("../assets/images/banner.png") },
        {
          id: 1,
          url: require("../assets/images/imgs1.png"),
        },
        {
          id: 2,
          url: require("../assets/images/imgs1.png"),
        },
        {
          id: 3,
          url: require("../assets/images/banner.png"),
        },
      ],
      categoryList: [],
     
      banner: [],
      newList: [],
      hotList: [],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }

    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
 
  created() {
    this.getInfo();
    window.addEventListener("popstate", this.popstate, false);
  },
  mounted() {
    if(this.imgArr.length>1){
      this.startInterval();
    }


  },
  destroyed() {
    window.addEventListener("popstate", this.popstate, false);
  },
  watch: {
    $route(to, from) {
      this.getInfo();
    }
  },
  methods: {
    popstate() {
     // this.reload();
    },
    gofirst(id){
      this.$router.push({ path: '/productList?category_id='+id });
    },


    gogoods(goods_id,ad_id){
      apiGetIsgo({goods_id:goods_id,ad_id:ad_id}).then((res) => {
        // 获取数据成功后的其他操作
         if(res.code==1){
           this.$router.push({ path: "/productDetail?id=" + goods_id+'&&ad_id='+ad_id });
         }else{
           return false;
         }
      }).catch((err) => {});
    },
    getInfo() {
      var that = this;
      apiGetIndex({})
        .then((res) => {
          // 获取数据成功后的其他操作

          that.categoryList = res.data.categorys;
          that.banner = res.data.banner;
          that.newList = res.data.newList;
          that.hotList = res.data.hotList;
        })
        .catch((err) => {});
    },



    //轮播图
    //开启定时器
    startInterval() {
      // 事件里定时器应该先清除在设置，防止多次点击直接生成多个定时器
      // clearInterval(this.timer);
      // this.timer = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex > this.imgArr.length - 1) {
          this.currentIndex = 0;
        }
      // }, 3000);
    },
  },
};
</script>
  <style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 0;
  max-width: 100vw;
  margin-bottom: 0;
  .toptitle {
    font-size: 20px;
    &::after {
      right: 0;
      height: 10px;
      bottom: 0;
    }
  }
  .toptitle.fs32 {
    font-size: 32px;
  }
}
.part1 {
  .mb5 {
    margin-bottom: 5px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mr10 {
    margin-right: 10px;
  }
  width: 100%;
  padding: 20px 0;
  background: #bf6ef8;
  .left {
    .plans:hover {
      color: #ff690d;
    }
    width: 280px;
    // height: 420px;
    background: #ffffff;
    border-radius: 8px;
    padding: 33px 0px 25px 15px;
    .icon {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: cover;
      margin-right: 7px;
    }
    .title {
      color: #ff690d;
      line-height: 25px;
      font-weight: bold;
    }
    .btn {
      width: 208px;
      text-align: center;
      height: 44px;
      line-height: 42px;
      border-radius: 22px;
      border: 1px solid #ff7210;
      color: #ff7210;
      margin: 0 0 0 20px;
      font-size: 16px;
      display: block;
    }
  }
}
.hots_part,
.new_part {
  padding: 50px 119px 30px 119px;
  position: relative;
  .customer {
    width: 70px;
    height: 174px;
    display: block;
    object-fit: cover;
    position: fixed;
    z-index: 9;
    right: 0;
    top: 50%;
  }
  .mb40 {
    margin-bottom: 40px;
  }

  .index_icon4 {
    width: 36px;
    height: 36px;
    display: block;
    object-fit: cover;
    margin-right: 10px;
  }
  .product {
    width: 224px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 30px;
    .img {
      widows: 224px;
      height: 246px;
      display: block;
      object-fit: cover;
    }
    .main {
      padding: 11px 18px 16px 20px;
      .pr10 {
        padding-right: 8px;
      }
    }
    .cart_icon {
      width: 39px;
      height: 39px;
      display: block;
      object-fit: cover;
      margin-top: 10px;
    }
  }
  .product:last-of-type {
    margin-right: 0;
  }
  .product:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.61);
  }
  .btn {
    width: 122px;
    height: 38px;
    line-height: 36px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.5);
    margin: 0 auto;
    .arrow {
      width: 6px;
      height: 9px;
      display: block;
      object-fit: cover;
      margin-left: 6px;
    }
  }
}
.new_part {
  padding: 50px 119px 30px 119px;
  background: #9d58f1;
  .index_icon4 {
    width: 36px;
    height: 32px;
    margin-right: 6px;
  }
  .btn {
    width: 122px;
    height: 38px;
    line-height: 36px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    margin: 0 auto;
    .arrow {
      width: 6px;
      height: 9px;
      display: block;
      object-fit: cover;
      margin-left: 6px;
    }
  }
}

.m-ellipsis-l2 {
  height: 48px;
  line-height: 24px;
  margin: 20px 0 0;
  position: relative;
}
// 轮播图
* {
  padding: 0;
  margin: 0;
}
/* 清除li前面的圆点 */
li {
  list-style-type: none;
}
.showImg {
  position: relative;
  width: 900px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 20px;
}
/* 轮播图片 */
.showImg img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

/* 箭头图标 */
.iconDiv {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border: 1px solid #666;
  border-radius: 15px;
  background-color: rgba(125, 125, 125, 0.2);
  line-height: 30px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
}
.iconDiv:hover {
  background-color: white;
}
.icon-left {
  left: 10px;
}
.icon-right {
  right: 10px;
}

/* 控制圆点 */
.banner-circle {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
}
.banner-circle ul {
  margin: 0 50px;
  height: 100%;
  text-align: right;
}
.banner-circle ul li {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 7px;
  background-color: rgba(125, 125, 125, 0.8);
  cursor: pointer;
}
.active {
  background-color: black !important;
}
</style>
  