<template>
  <div class="bg-main">
    <Header></Header>
    <div class="content">
      <div class="pagemain" v-if="goodsDetail">
        <div class="lh20 fs14 mb20">
          <span class="col9 pointer plans" @click="goindex">当前位置: 首页 》</span>
<!--          <span class="col9 pointer plans">热销商品 》</span>-->

          <span class="col9 pointer plans" @click="gourl(index)" v-for="(item, index) in url_arr">{{
              item.name
            }} 》</span>
          <span class="col3">商品详情</span>
        </div>
        <div class="part1 flex">
          <img class="imgs1" :src="goodsDetail.image_text" alt="" />
          <div class="flex-grow-1 pt15">
            <div class="fs26 col3 fwb lh38 mb20">{{goodsDetail.goods_name}}</div>
            <div class="fs14 col9 lh20 mb20">
              <span class="mr50">库存: {{total_stock}}</span><span>已售: {{sales}}</span>
            </div>

            <template v-for="(item,index) in goodsDetail.spec">
              <div class="fs16 col3 lh22 mb10">{{item.name}}</div>
              <div :class="index==0?'flex flex-wrap':'flex flex-wrap mb5'">

                <div
                    class="fs16 mb20 mr20 pointer tab1"
                    v-for="(item1, index1) in item.items"
                    :key="index1"
                    @click="choosespec(item1.item_id,index)"
                    :class="selected_item_key.indexOf(item1.item_id)!==-1 ? 'active' : 'unactive'"
                >

                  {{ item1.item }}
                </div>
              </div>

            </template>


            <div class="fs16 col3 lh22 mb10">数量</div>
            <div class="flex-box num_part mb30">
              <img
                class="add_icon pointer"
                v-on:click="subtract()"
                src="../assets/images/sub_icon.png"
                alt=""
              />
              <input
                class="flex-grow-1 tc"
                type="text"
                readonly
               v-model="goods_num"
                size="1"
              />
              <img
                class="add_icon col-6f pointer"
                v-on:click="add()"
                src="../assets/images/add_icon.png"
                alt=""
              />
            </div>
            <div class="lh56 mb20">
              <span class="fs40 red fwb">{{selected_money.unit}}{{goods_price}}</span
              ><span class="mb5 fs20 col9 del ml10">{{selected_money.unit}}{{original_price}}</span>
            </div>
            <div class="flex">
              <div
                @click="change"
                class="pay_part pr flex-box flex-center fs16 col3"
              >
                <img class="icon" :src="selected_money.image_text" alt="" />
                <div class="ml5 mr15">{{selected_money.name}}</div>
                <div class="mr20">{{selected_money.unit}}{{selected_money.desc}}</div>
                <img
                  class="arrow"
                  src="../assets/images/arrow_down.png"
                  alt=""
                />
                <div v-if="show" class="country_part fs16 col3">
                  <div
                    v-for="(item, index) in money_type"
                    :key="index"
                    @click="choosed(index)"
                    class="flex-box flex-center country"
                  >
                    <div class="ml5 mr15">{{item.name}}</div>
                    <div class="mr20">{{item.unit}}{{item.desc}}</div>
                  </div>
                </div>
              </div>
              <div class="buy_btn colf tc fs24 fwb ml50 pointer" @click="gobuy">立即购买</div>
            </div>
          </div>
        </div>
        <div class="part2 flex">
          <div class="flex-grow-1 mr30">
            <div class="flex mb25">
              <div
                class="fs24 fwb mr62 pointer plans"
                v-for="(item, index) in tablist3"
                :key="index"
                @click="testTabClick3(index)"
                :class="tabIndex3 == index ? 'col3' : 'cola8 '"
              >
                {{ item }}
              </div>
            </div>
            <div v-if="tabIndex3 == 0" class="detail_part" v-html="goodsDetail.content">

            </div>
            <template v-if="tabIndex3 == 1">
              <div class="m-nothing " v-if="nowList.total==0" >
                <img src="../assets/images/empty.png" alt="" />
                <div class="hit_txt">暂无评论~</div>
              </div>
              <div
                v-for="(item, index) in nowList.data"
                :key="index"
                class="review_part mb20 flex"
              >
                <img class="avatar" :src="item.user.avatar" alt="" />
                <div class="name m-ellipsis fs16 col3 lh22">{{item.user.nickname}}</div>
                <div class="flex-grow-1">
                  <div class="flex">
                    <img
                      class="star"
                      v-for="(item, index) in item.star"
                      :key="index"
                      src="../assets/images/star.png"
                      alt=""
                    />
                  </div>
                  <div class="fs14 col3 lh20">
                    {{item.content}}
                  </div>
                </div>
              </div>
              <div v-if="nowList.total>0">
                <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div>
                <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
                <Pagination v-else :sumCount="nowList.total" :perCount="nowList.per_page" @pageTurn="pageTurn"></Pagination>
              </div>
            </template>
          </div>
          <div>
            <div class="fs24 col3 lh34 mb25 fwb">相似商品推荐</div>
            <router-link
              v-for="(item, index) in recommendList"
              :key="index"
              class="product pointer"
              :to="{path: '/productDetail?id='+item.id}"

            >
              <img class="img" :src="item.image_text" alt="" />
              <div class="main">
                <div class="m-ellipsis fs16 col3 fwb lh22 mb5">{{item.goods_name}}</div>
                <div class="fs12 col9 lh18 mb5">{{item.desc}}</div>
                <div class="flex">
                  <div class="flex-grow-1">
                    <div class="lh34 mb3">
                     <span class="fs24 red fwb pr10">¥{{ item.price }}</span
                     ><span class="fs16 col9 mb3">¥{{ item.origin_price }}</span>
                    </div>
                    <div class="fs12 col9 lh17">已售:{{item.total_sales}}</div>
                  </div>
                  <img
                    class="cart_icon"
                    src="../assets/images/addCart.png"
                    alt=""
                  />
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
    <script>
    import Pagination from "@/components/pagination.vue";
    import {
      apiGetCheckStock,
      apiGetGetMoney,
      apiGetGoods,
      apiGetGoodsComment,
      apiGetGoodsDetail, apiGetIsBuy,
      apiGetPrice
    } from "../request/api";
    import CryptoJS from 'crypto-js';
export default {
  components: {
    Pagination,
  },
  data() {
    return {

      tabIndex: 0,
      tabIndex2: 0,
      count: 0,

      selected: "",
      tablist3: ["产品详情", "商品评价"],
      tabIndex3: 0,
      show: false,
      goodsDetail:null,
      recommendList:[],
      goods_id:0,
      ad_id:0,

      comments:0,

      selected_item_key:[],
      nowList:{  total: 0,
        current_page: 0,
        last_page: 1,
        data: []
      },
      url_arr: [],
      money_type:[],
      selected_money:null,
      goods_num:1,
      goods_key:'',
      goods_price:'',
      sale_price:'',
      original_price:'',
      goods_image:'',
      sales:0,
      total_stock:0,
    };
  },
  created() {
    // 在组件创建之后，把默认选中项的value值赋给绑定的属性
    //如果没有这句代码，select中初始化会是空白的，默认选中就无法实现

    let _that = this;
    let params=_that.$route.query;
    if(params.id){
      _that.goods_id=params.id;
    }
    if(params.ad_id){
      _that.ad_id=params.ad_id
    }
    console.log(params.rec)
    if(params.rec){
      let rec=params.rec;
       var c=this.decryptData(decodeURIComponent(rec));
       console.log(c)
      if(c){
        this.url_arr=Array.isArray(c)?c:[]
      }
    }
    this.init_Data();
    this.getDetail();
  },
  watch: {
    $route(to, from) {
      let _that = this;
      let params=_that.$route.query;
      if(params.id){
        _that.goods_id=params.id;
      }
      if(params.ad_id){
        _that.ad_id=params.ad_id
      }
      console.log(params.rec)
      if(params.rec){
        let rec=params.rec;
        var c=this.decryptData(decodeURIComponent(rec));
        console.log(c)
        if(c){
          this.url_arr=Array.isArray(c)?c:[]
        }
      }
      this.init_Data();
      this.getDetail();
    }
  },
  mounted() {

    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    gourl(index){
      if(this.url_arr[index].url){
        this.$router.push({ path: this.url_arr[index].url });
      }
    },
    encryptData(data) {
      const secretKey = 'yxcz'; // 替换为你的密钥
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    },

// 解密方法
    decryptData(encryptedData) {
      const secretKey = 'yxcz'; // 替换为你的密钥
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return originalData;
    },
    choosespec(item,key) {
      var   that = this;
      var selected_item_key = that.selected_item_key;

      selected_item_key[key] = item;
      var item_key = selected_item_key.join("_")
      that.selected_item_key=selected_item_key;

      this.$forceUpdate()


      var json={};
      /**
       * { key: item_key,
          goodsId: that.data.goodsDetail.id}
       */
      json.key=item_key;
      json.goodsId=that.goodsDetail.id;
      json.money_type_id=that.selected_money.id;
      apiGetPrice(json).then(res => {
        // 获取数据成功后的其他操作
        if(res.code==1){
          that.goods_num=res.data.goods_num;
          that.goods_image=res.data.image;
          that.goods_price=res.data.shop_price;
          that.original_price=res.data.original_price
          that.selected_item_key=selected_item_key;
          that.total_stock=res.data.store_count;
          that.sales=res.data.total_sales;

          // that.setData({
          //   item_key_flag: true,
          //   image: res.data.image,
          //   key: res.data.key,
          //   keys: res.data.keys,
          //   key_name: res.data.key_name,
          //   price: res.data.shop_price,
          //   is_cart:res.data.is_cart,
          //   cart_num:res.data.cart_num,
          //   selected_item_key: selected_item_key,
          // })
        }else{
          if(res.code!=2&&res.msg){
            return that.$message.error(res.msg);
          }

        }

      }).catch(err=>{

      })

    },
    init_Data(){
      this.nowList.current_page=0;
      this.getList()
    },
    getList(){
      var json={},that=this;
      json.goods_id=that.goods_id;
      json.page = Number(that.nowList.current_page) + 1;
      console.log(json.page)

      if (json.page > 1 && that.nowList.current_page >= that.nowList.last_page) {
        return false;
      }
      apiGetGoodsComment(json).then(res => {
        // 获取数据成功后的其他操作
        const nowList = res.data;
        if (json.page > 1) {
          var now_nowList_data =that.nowList.data || [];
          var now_nowList_data = now_nowList_data.concat(nowList.data);
          nowList.data = now_nowList_data;

        }
        that.nowList= nowList;

      }).catch(err=>{

      })
    },
    getDetail(){
      let that =this;
      apiGetGoodsDetail({goods_id:that.goods_id}).then((res) => {
        // 获取数据成功后的其他操作

       that.goodsDetail=res.data.goods;

       that.recommendList=res.data.recommendList;
       console.log(that.recommendList)
        that.comments=res.data.comments;
       if(that.comments==0){
         that.tablist3[1]='商品评价';
       }else{
         that.tablist3[1]='商品评价'+'（'+that.comments+'条）';
       }
       that.money_type=res.data.money_type;
       if(res.data.money_type.length>0){
         that.selected_money=res.data.money_type[0];
       }
       if(res.data.goods){
         if(res.data.goods.stock>0){
           that.goods_num=1;
         }else{
           that.goods_num=0
         }
       }else{
         that.goods_num=0;
       }
       that.goods_price=res.data.goods.price;
       that.original_price=res.data.goods.origin_price;
       that.total_stock=res.data.goods.stock;
       that.sales=res.data.goods.total_sales;
      }).catch((err) => {});
    },

    godetail(id){
      this.$router.push({ path: '/productDetail?id='+id})
    },
    goindex(){
      this.$router.push({ path: "/" });
    },
    change() {
      this.show = !this.show;
    },
    choosed(index){
      this.selected_money=this.money_type[index];
      this.chooseMoney();
    },
    chooseMoney(){
      var json={},that=this;
      json.goodsId=that.goods_id;
      json.key=that.selected_item_key.join('_');
      json.money_type_id=that.selected_money.id;

      apiGetGetMoney(json).then(res => {
        // 获取数据成功后的其他操作
       if(res.code==1){
           that.goods_price=res.data.shop_price;
           that.original_price=res.data.original_price
       }

      }).catch(err=>{

      })
    },
    handleScroll(){
      var that =this;

      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (scrollHeight - (scrollTop + clientHeight) < 5) {
        // 当距离底部5px以内并且还有更多数据时加载新数据

        if (that.nowList.current_page >= that.nowList.last_page) {
          return false;
        } else {
          that.getList();
        }

      }

    },
    testTabClick(index) {

      this.tabIndex = index;
    },
    testTabClick2(index) {

      this.tabIndex2 = index;
    },
    testTabClick3(index) {

      this.tabIndex3 = index;
      if(index==1){
        this.init_Data()
      }
    },
    add: function (count) {

      var json={},that=this;
      json.goods_id=that.goods_id;
      json.key=that.selected_item_key.join('_');
      json.num=that.goods_num+1;

      apiGetCheckStock(json).then(res => {
        // 获取数据成功后的其他操作
        if(res.code==1){
          that.goods_num=json.num;
        }else{
          return that.$message.error(res.msg);
        }

      }).catch(err=>{

      })

    },
    subtract: function (count) {
      if (this.goods_num <= 0) {
        return this.$message.error("不能再减少了");

      } else {
        this.goods_num -= 1;
      }
    },
    gobuy(){
      var json={},that=this;
      json.goods_id=that.goods_id;
      json.key=that.selected_item_key.join('_');
      json.num=that.goods_num;
     json.selected_money_id=that.selected_money.id;
      json.ad_id=that.ad_id;

      apiGetIsBuy(json).then(res => {
        // 获取数据成功后的其他操作
        if(res.code==1){
          this.$router.push({ path: "/confirmOrder?params="+encodeURIComponent(this.encryptData(json)) });
      // this.$router.push({ path: "/productDetail?params='+'encodeURIComponent(this.encryptData(this.url_arr)) })";
        }else{
          return that.$message.error(res.msg);
        }

      }).catch(err=>{

      })
    },
  },
};
</script>
    <style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.pagemain {
  width: 1215px;
  margin: 0 auto;
  .part1 {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 25px 32px 40px;
    margin-bottom: 30px;
    .imgs1 {
      width: 380px;
      height: 481px;
      display: block;
      object-fit: cover;
      margin-right: 44px;
    }
    .active,
    .unactive,.tab1:hover {
      width: 131px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      background: rgba(143, 90, 252, 0.1);
      border-radius: 5px;
      color: #8f5afc;
    }
    .unactive {
      background: #eeeeee;
      color: #666;
    }
    .num_part {
      width: 131px;
      height: 34px;
      line-height: 34px;
      border-radius: 5px;
      border: 1px solid rgba(151, 151, 151, 0.1);
      .add_icon {
        width: 34px;
        height: 34px;
        display: block;
        object-fit: cover;
      }
    }
    .buy_btn {
      width: 261px;
      text-align: center;
      height: 70px;
      line-height: 70px;
      background: #8f5afc;
      border-radius: 10px;
    }
    .pay_part {
      width: 217px;
      height: 70px;
      line-height: 68px;
      background: #f5f5f5;
      border-radius: 14px;
      border: 1px solid #e5e5e5;
      select {
        background: #f5f5f5;
        width: auto !important;
      }
      .icon {
        width: 44px;
        height: 30px;
        display: block;
        object-fit: cover;
      }
      .arrow {
        width: 12px;
        height: 8px;
        display: block;
        object-fit: cover;
      }
      .country_part {
        background: #fff;
        position: absolute;
        width: 100%;
        z-index: 2;
        top: 70px;
        left: 0;
        max-height: 280px;
        overflow-y: scroll;
        .country {
          line-height: 70px;
        }
        .country:hover {
          background: #8f5afc;
          color: #fff;
        }
      }
    }
  }
  .part2 {
    padding-left: 8px;
    padding-bottom: 144px;
    .detail_part {
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
      border-radius: 6px;
      padding: 32px 24px 89px 31px;
      .detail_img {
        width: 894px;
        height: 470px;
        display: block;
        object-fit: cover;
      }
    }
  }
  .mb40 {
    margin-bottom: 40px;
  }

  .product {
    display: block;
    width: 224px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;

    .img {
      widows: 224px;
      height: 246px;
      display: block;
      object-fit: cover;
    }
    .main {
      padding: 11px 18px 16px 20px;
      .pr10 {
        padding-right: 8px;
      }
    }
    .cart_icon {
      width: 39px;
      height: 39px;
      display: block;
      object-fit: cover;
      margin-top: 10px;
    }
  }
  .product:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.61);
  }
  .review_part {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 30px 29px 28px 24px;
    .avatar {
      width: 44px;
      height: 44px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 13px;
      margin-right: 10px;
    }
    .name {
      // width: 114px;
      width: 60px;
      margin-right: 50px;
      margin-top: 24px;
    }
    .star {
      width: 17px;
      height: 17px;
      display: block;
      object-fit: cover;
      margin-right: 12px;
      margin-bottom: 16px;
    }
  }
}
</style>
    