<template>
  <div class="bg-main">
    <Header :active="true"></Header>
    <div class="content">
      <div class="hots_part">
        <div class="lh20 fs14 mb30">
          <span class="col9 " 
            >当前位置: <span class="pointer plans" @click="goindex"> 首页 》</span></span
          >

          <span class="col3" v-for="(item, index) in url_arr">{{
            item.name
          }} 》</span>
        </div>
        <div class="fs30 col3 fwb lh42 mb30">商品列表</div>
        <div class="flex  flex-wrap pb25">
          <div
            v-for="(item, index) in nowList.data"
            :key="index"
            @click="godetail(item.id)"
            class="product pointer"
          >
            <img class="img" :src="item.image_text" alt="" />
            <div class="main">
              <div class="m-ellipsis fs16 col3 fwb lh22 mb5">
                {{ item.goods_name }}
              </div>
              <div class="fs12 col9 lh18 mb5">{{ item.desc }}</div>
              <div class="flex">
                <div class="flex-grow-1">
                  <div class="lh34 mb3">
                    <span class="fs24 red fwb pr10">¥{{ item.price }}</span
                    ><span class="fs16 col9 mb3">¥{{ item.origin_price }}</span>
                  </div>
                  <div class="fs12 col9 lh17">已售:{{ item.total_sales }}</div>
                </div>
                <img
                  class="cart_icon pointer"
                  src="../assets/images/addCart.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="m-nothing " v-if="nowList.total==0" >
          <img src="../assets/images/empty.png" alt="" />
          <div class="hit_txt">暂无数据~</div>
        </div>
        <!--  -->
        <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div>
        <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
        <Pagination
          v-else
          :sumCount="nowList.total"
          :perCount="pageSize"
          @pageTurn="pageTurn"
        ></Pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
    <script>
import Pagination from "@/components/pagination.vue";
import {apiGetCategoryDetail, apiGetGoods} from "../request/api";
import CryptoJS from 'crypto-js';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isShowNavBarLoading: false,
      nowList: { total: 0, current_page: 0, last_page: 1, data: [] },
      pageSize: 15,
      url_arr: [],
      type: 0,
      category_id: 0,
      second_id: 0,
      keywords:''
    };
  },
  created() {
    let _that = this;
    let params = _that.$route.query;
    if (params.type) {
      if (params.type == 1) {
        _that.url_arr = [{ name: "热销产品", url: "/productList?type=1" }];
      }
      if (params.type == 2) {
        _that.url_arr = [{ name: "最新产品", url: "/productList?type=1" }];
      }
    }
    if (params.category_id) {
      _that.category_id = params.category_id;
      this.getCategory()
    }
    if (params.second_id) {
      _that.second_id = params.second_id;
      this.getCategory1()
    }
    if(params.keywords){
      _that.url_arr = [{ name: params.keywords, url: "/productList?keywords="+params.keywords }];
    }
    console.log(params)

    _that.init_Data();
  },


  watch: {
    $route(to, from) {
      let _that = this;
      let params = _that.$route.query;
      if (params.type) {
        if (params.type == 1) {
          _that.url_arr = [{ name: "热销产品", url: "/productList?type=1" }];
        }
        if (params.type == 2) {
          _that.url_arr = [{ name: "最新产品", url: "/productList?type=1" }];
        }
      }
      if (params.category_id) {
        _that.category_id = params.category_id;
        this.getCategory()
      }
      if (params.second_id) {
        _that.second_id = params.second_id;
        this.getCategory1()
      }
      if(params.keywords){
        _that.keywords=params.keywords;
        _that.url_arr = [{ name: params.keywords, url: "/productList?keywords="+params.keywords }];
      }


      _that.init_Data();
    }
  },
  

  methods: {
    encryptData(data) {
      const secretKey = 'yxcz'; // 替换为你的密钥
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    },

// 解密方法
 decryptData(encryptedData) {
   const secretKey = 'yxcz'; // 替换为你的密钥
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return originalData;
},

init_Data() {
      this.nowList.current_page = 0;
      this.getList();
    },
    getCategory(){
      var that =this,json={};
      json.id=that.category_id;
      apiGetCategoryDetail(json)
          .then((res) => {
            // 获取数据成功后的其他操作
            if(res.data){
              that.url_arr = [{ name: res.data.name, url: "/productList?category_id="+that.category_id }];
            }
          })
          .catch((err) => {});
    },
    getCategory1(){
      var that =this,json={};
      json.id=that.second_id;
      apiGetCategoryDetail(json)
          .then((res) => {
            // 获取数据成功后的其他操作
            if(res.data){
              that.url_arr = [{ name: res.data.name, url: "/productList?second_id="+that.second_id }];
            }
          })
          .catch((err) => {});
    },
    getList() {
      var json = {},
        that = this;
      json.type = that.type;
      json.category_id = that.category_id;
      json.second_id = that.second_id;

      json.page = Number(that.nowList.current_page) + 1;
      json.keywords=that.keywords;

      if (
        json.page > 1 &&
        that.nowList.current_page >= that.nowList.last_page
      ) {
        return false;
      }
      apiGetGoods(json)
        .then((res) => {
          // 获取数据成功后的其他操作

          var nowList = res.data;

          that.nowList = nowList;
        })
        .catch((err) => {});
    },
    pageTurn(page) {
      this.nowList.current_page = page;
      this.getList();
    },
    goindex() {
      this.$router.push({ path: "/" });
    },
    godetail(id){
      var json={};
      json.url_arr=this.url_arr;
      json.id=id;
      console.log(this.url_arr);
      console.log(this.encryptData(this.url_arr))
      console.log(this.decryptData(this.encryptData(this.url_arr)));
      console.log(encodeURI(this.encryptData(this.url_arr)) )
      this.$router.push({ path: "/productDetail?id="+id+'&rec='+encodeURIComponent(this.encryptData(this.url_arr)) });
    },
  },
};
</script>
    <style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.hots_part {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  background: #f8f8f8;
  .mb40 {
    margin-bottom: 40px;
  }

  .product {
    width: 224px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 30px;
    .img {
      widows: 224px;
      height: 246px;
      display: block;
      object-fit: cover;
    }
    .main {
      padding: 11px 18px 16px 20px;
      .pr10 {
        padding-right: 8px;
      }
    }
    .cart_icon {
      width: 39px;
      height: 39px;
      display: block;
      object-fit: cover;
      margin-top: 10px;
    }
  }
  .product:nth-of-type(5n) {
    margin-right: 0;
  }
  .product:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.61);
  }
}

.m-ellipsis-l2 {
  height: 48px;
  line-height: 24px;
  margin: 20px 0 0;
  position: relative;
}
</style>
    