<template>
  <div class="bg-main">
    <Header></Header>
    <div class="content">
      <div class="pagemain flex">
        <div class="left_part mr20">
          <div class="info_part" v-if="userInfo">
            <img
                class="avatar pointer"
                :src="userInfo.avatar"
                alt=""
            />
            <div class="flex-box flex-center">
              <div class="name fs20 col26 lh28 mr5 pointer plans">{{userInfo.nickname}}</div>
                <img
                  class="gender_icon"
                  v-if="userInfo.gender==2"
                 src="../assets/images/gender_icon1.png"
                  alt=""
              />
              <img
                  v-if="userInfo.gender==1"
                  class="gender_icon"
                  src="../assets/images/gender_icon2.png"
                  alt=""
              />
            </div>
          </div>
          <div class="order_part mb30">
            <router-link
                class="tablist flex-box fs14 fwb pointer"
                v-for="(item, index) in tablist"
                :key="index"
                @mouseover="showTabClick(index)"
                :to="item.url"
                :class="tabIndex == index ? 'active colf' : 'unactive col26'"
            >
              <img
                  :key="index"
                  v-if="tabIndex == index || showIndex == index"
                  class="icon"
                  :src="item.activeIcon"
                  alt=""
              />
              <img v-else class="icon" :src="item.Icon" alt="" />
              <div class="flex-grow-1">{{ item.name }}</div>
            </router-link>
          </div>
        </div>
        <div v-if="tabIndex != 4" class="flex-grow-1 right_part mb30">
          <template>
            <!-- orderstate==0 -->

            <template v-if="order">


            </template>
            <template >
              <div class="main">
                <div class="pb35">
                  <div class="lh20 fs14 mb35 fwb">
                    <span class="col9">我的订单 / </span>
                    <span class="col3">订单详情</span>
                  </div>
                  <div class="flex plr10 state_part mb35">
                    <div class="flex-grow-1">
                      <img
                          v-if="order.status >= 0"
                          class="state_icon"
                          src="../assets/images/state_icon_on.png"
                          alt=""
                      />
                      <div class="tc fs14 col3 lh20 mb10">待支付</div>
<!--                      <div v-if="order.status >= 0" class="fs12 lh18 col-purple tc">-->
<!--                        <u-count-down :time="30 * 60 * 60 * 1000" format="HH:mm:ss"></u-count-down>-->
<!--                      </div>-->
                    </div>
                    <div :class="order.status >= 1 ? 'line1_on' : 'line1'"></div>
                    <div class="flex-grow-1">
                      <img
                          v-if="order.status >= 1"
                          class="state_icon"
                          src="../assets/images/state_icon.png"
                          alt=""
                      />
                      <img
                          v-else
                          class="state_icon"
                          src="../assets/images/state_icon.png"
                          alt=""
                      />
                      <div
                          class="tc fs14 lh20 mb10"
                          :class="order.status >= 1 ? 'col3' : 'colc'"
                      >
                        待发货
                      </div>
                    </div>
                    <div :class="order.status >= 1 ? 'line2_on' : 'line2'"></div>
                    <div class="flex-grow-1">
                      <img
                          v-if="order.status >= 2"
                          class="state_icon"
                          src="../assets/images/state_icon_on.png"
                          alt=""
                      />
                      <img
                          v-else
                          class="state_icon"
                          src="../assets/images/state_icon.png"
                          alt=""
                      />
                      <div
                          class="tc fs14 lh20 mb10"
                          :class="order.status >= 2 ? 'col3' : 'colc'"
                      >
                        已完成
                      </div>
                    </div>
                  </div>
                  <div class="fs20 col3 fwb lh28 mb30">订单信息</div>
                  <div class="flex flex-between fs14 col3 lh20 mb25 pr10">
                    <div class="flex-grow-1">订单号: {{order.order_sn}}</div>
                    <div class="flex-grow-1 pl55">
                      下单时间: {{order.createtime_text}}
                    </div>
                    <div class="flex-grow-1 tr" v-if="order.status>0">
                      下单时间: {{order.paytime_text}}
                    </div>
                  </div>
                  <div class="flex flex-between fs14 col3 lh20 mb40 pr10">
                    <div class="flex-grow-1">邮箱地址: {{order.email}}</div>
<!--                    <div v-if="status != 0" class="flex-grow-1 pl55">-->
<!--                      支付方式: 支付宝-->
<!--                    </div>-->
                    <div class="flex-grow-1 tr"></div>
                  </div>
                  <div class="fs24 col3 lh34 fwb mb30">商品信息</div>

                  <div class="border_b flex-box pb47" v-for="(item,index) in order.goods">
                    <img
                        class="imgs1"
                        :src="item.image_text"
                        alt=""
                    />
                    <div
                        class="flex-grow-1 flex-box flex-between fs16 col3 fwb"
                    >
                      <div>{{item.goods_name}}</div>
                      <div>{{item.key_name}}</div>
                      <div>数量:{{item.goods_num}}</div>
                      <div>单价：¥{{item.goods_price}}</div>
                    </div>
                  </div>
                  <div class="flex-box flex-end mt20">
                    <div class="fs14 col6 lh20 mr65">订单总金额</div>
                    <div class="fs14 col3 lh20">{{order.currency_unit}}{{order.total_fee}}</div>
                  </div>
                  <div class="flex-box flex-end mt15">
                    <div class="fs14 col6 lh20 mr50">支付金额</div>
                    <div class="fs20 red fwb lh28">{{order.currency_unit}}{{order.total_fee}}</div>
                  </div>
                  <template v-if="order.status == 0">
                    <div>
                      <div class="mt55 fs24 col3 fwb lh34 mb40">
                        选择支付方式
                      </div>
                      <div class="pay_part">
                        <img
                            class="icon"
                            src="../assets/images/pay_icon.png"
                            alt=""
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="order.status == 0" class="flex-box flex-end pt15">
                  <div class="fs18 col9 tc qx_buy" @click="cancelOrder">取消订单</div>
                  <div class="fs18 colf tc btn_buy" @click="payagain">立即支付</div>
                </div>
                <router-link :to="{path:'/evaluate?order_id='+order.id}" v-if="order.status == 2&&order.is_comment==0" class="flex-box flex-end pt15">
                  <div class="fs18 colf tc btn_buy">我要评价</div>
                </router-link>
              </div>
            </template>

            <!--  -->
          </template>




        </div>

      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {apiGetCancel,apiPayagain, apiGetHelps, apiGetMyOrderDetail, apiGetUserInfo} from "../request/api";

export default {
  data() {
    return {
      orderstate: 2, //0:我的订单 1：订单详情 2：发表评价
      status: 2, //0:订单详情待支付 1：订单详情待发货 2：订单详情已完成
      review: 1, //0:我的评价 1：评价详情
      tablist: [
        {
          activeIcon: require("../assets/images/order_icon1_on.png"),
          Icon: require("../assets/images/order_icon1.png"),
          name: "我的订单",
          url:'/myOrder'
        },
        {
          activeIcon: require("../assets/images/order_icon2_on.png"),
          Icon: require("../assets/images/order_icon2.png"),
          name: "消息中心",
          url: '/news'
        },
        {
          activeIcon: require("../assets/images/order_icon3_on.png"),
          Icon: require("../assets/images/order_icon3.png"),
          name: "帮助中心",
          url: '/helps'
        },
        {
          activeIcon: require("../assets/images/order_icon4_on.png"),
          Icon: require("../assets/images/order_icon4.png"),
          name: "个人信息",
          url: '/mine'
        },
        {
          activeIcon: require("../assets/images/order_icon5_on.png"),
          Icon: require("../assets/images/order_icon5.png"),
          name: "联系客服",
          url: '/kefu'
        },
        {
          activeIcon: require("../assets/images/order_icon6_on.png"),
          Icon: require("../assets/images/order_icon6.png"),
          name: "我的评价",
          url: '/comments'
        },
      ],
      tabIndex: 0,
      showIndex: null,
      tablist2: ["全部订单", "待支付", "待发货", "已完成"],
      tabIndex2: 0,
      genderlist: ["男", "女"],
      genderIndex: 0,
      show: false,

      //评分
      qStars: [false, false, false, false, false],
      eStars: [false, false, false, false, false],
      yellow: require("../assets/images/star.png"),
      gray: require("../assets/images/star_icon.png"),
      formData: {
        taskid: "",
        qscore: 0,
        escore: 0,
      },
      userInfo:null,
      isShowNavBarLoading:false,
      nowList:{  total: 0,
        current_page: 0,
        last_page: 1,
        data: [],
        per_page:15
      },
      pageSize:15,
      id:0,
      order:null,
      osInfo:''
    };
  },

  mounted() {
    // 页面滚动到底部
    this.$nextTick(() => {
      // 一定要用nextTick
      // this.setPageScrollTo();
      // //页面滚动条距离顶部高度等于这个盒子的高度
      // this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
    });
  },
  created() {
    let _that = this;
    let params=_that.$route.query;
    if(params.id){
      _that.id=params.id;
    }
    _that.getDetail();
    _that.getUserInfo();
    this.getOSInfo()
  },
  watch: {
    $route(to, from) {
      let _that = this;
      let params=_that.$route.query;
      if(params.id){
        _that.id=params.id;
      }
      _that.getDetail();
      _that.getUserInfo();
    }
  },

  methods: {
    getOSInfo() {
      const userAgent = navigator.userAgent;
      let os = '未知系统';
      if (userAgent.indexOf('Windows')!== -1) {
        os = 'Windows ';
      } else if (userAgent.indexOf('Mac')!== -1) {
        os = 'Mac';
      } else if (userAgent.indexOf('Linux')!== -1) {
        os = 'Linux ';
      } else if (userAgent.indexOf('Android')!== -1) {
        os = 'Android ';
      } else if (userAgent.indexOf('iPhone')!== -1 || userAgent.indexOf('iPad')!== -1) {
        os = 'iOS ';
      }
      this.osInfo = os;
    },
    payagain(){
      var json={},that =this;
      json.id=that.id;
      json.os=that.osInfo;
      apiPayagain(json).then(res=>{
        if(res.code==1){
          window.open(res.data.url, '_blank');
        }else{
          alert(res.msg);
        }
      }).catch(err=>{

      })
    },
    getUserInfo(){
      var json={},that=this;
      apiGetUserInfo(json).then(res => {
        // 获取数据成功后的其他操作
        if(!res.data.is_login){
          that.$message.error("请登录");
          that.$router.push({ path: "/login" });
        }
        that.userInfo=res.data.userinfo;

      }).catch(err=>{

      })
    },
    cancelOrder(){
      var json={},that=this;
      json.id=that.id;
      apiGetCancel(json).then(res => {
        // 获取数据成功后的其他操作
        if(res.code==1){
         that.$message.success(res.msg);
         that.reload();
        }else{
          return that.$message.error(res.msg);
        }

      }).catch(err=>{

      })
    },
    getDetail(){
      var json={},that=this;
      json.id=that.id;
      apiGetMyOrderDetail(json).then(res => {
        // 获取数据成功后的其他操作
       that.order=res.data;

      }).catch(err=>{

      })
    },
    onChange(e) {
      this.timeData = e
    },
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
    },
    showTabClick(index) {
      this.showIndex = index;
    },
    testTabClick2(index) {
      console.log(index);
      this.tabIndex2 = index;
    },
    genderClick(index) {
      this.genderIndex = index;
    },
    showclick() {
      this.show = !this.show;
    },

  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.pagemain {
  width: 1208px;
  margin: 0 auto;
}
.left_part {
  width: 280px;
  .info_part {
    padding: 57px 0;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    margin-bottom: 12px;
    .avatar {
      width: 114px;
      height: 114px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 14px;
    }
    .name {
      max-width: 170px;
    }
    .gender_icon {
      width: 10px;
      height: 16px;
      display: block;
    }
  }
  .order_part {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 10px;
    .tablist {
      height: 60px;
      line-height: 60px;
      padding-left: 20px;
      .icon {
        width: 16px;
        height: 16px;
        display: block;
        object-fit: cover;
        margin-right: 9px;
      }
    }
    .active,
    .tablist:hover {
      background: #8f5afc;
      border-radius: 10px;
      color: #fff;
    }
    .unactive {
      background: transparent;
    }
  }
}
.right_part {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 30px 40px;
  .product_part1 {
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;
    .product_top {
      height: 44px;
      line-height: 44px;
      background: #f3f3f3;
      border-radius: 4px;
    }
    .tab1 {
      width: 225px;
      .imgs1 {
        width: 85px;
        height: 93px;
        display: block;
        object-fit: cover;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 15px;
      }
    }
    .tab2 {
      width: 66px;
      margin-right: 35px;
    }
    .tab3 {
      width: 140px;
      margin-right: 25px;
    }
    .tab4 {
      width: 82px;
      margin-right: 50px;
      .zt_btn1,
      .zt_btn2,
      .zt_btn3 {
        width: 69px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        background: #db1919;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        margin: 0 auto;
      }
      .zt_btn2 {
        background: #8f5afc;
      }
      .zt_btn3 {
        background: #cccccc;
      }
    }
    .tab5 {
      width: 57px;
      margin-right: 50px;
    }
  }
  //   个人信息
  .personal_info {
    width: 301px;
    min-height: 900px;
    margin: 0 auto;
    .avatar2 {
      width: 128px;
      height: 128px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 27px auto 16px;
    }
    .revise_btn {
      width: 144px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      background: #8f5afc;
      border-radius: 29px;
      margin: 0 auto 30px;
    }
    input {
      width: 301px;
      height: 55px;
      line-height: 55px;
      background: rgba(186, 202, 209, 0.2);
      border-radius: 28px;
      padding-left: 17px;
    }
    .gender_part:last-of-type {
      margin-right: 0;
    }
    .active_gender,
    .unactive_gender,
    .gender_part:hover {
      width: 135px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      color: #8f5afc;
      border-radius: 28px;
      background: rgba(143, 90, 252, 0.2);
    }
    .unactive_gender {
      background: rgba(186, 202, 209, 0.2);
      color: #333333;
    }
    .save_btn {
      width: 301px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      background: #8f5afc;
      border-radius: 28px;
      margin: 0 auto;
    }
  }
}
.minh940 {
  min-height: 940px;
}
.border_b {
  border-bottom: 1px solid #e9e9e9;
}
.message_icon2 {
  width: 36px;
  height: 36px;
  display: block;
  object-fit: cover;
  margin-right: 6px;
}

// 客服聊天
.wrap {
  width: 598px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
}
.titleBox {
  height: 87px;
  width: 100%;
  line-height: 87px;
  background: #fbfbfb;
}
.kf_avatar {
  width: 48px;
  height: 48px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.infoBottom {
  height: 168px;
  padding: 10px 37px 20px 15px;
  background: #fff;
}
/* 输入框 */
.infoInput {
  height: 126px;
  width: 100%;
  border: none;
  resize: none;
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
}
.fasong {
  width: 81px;
  text-align: center;
  height: 43px;
  line-height: 41px;
  border-radius: 6px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  color: #333333;
  margin-top: 97px;
}
.infoIcon {
  height: 24px;
  width: 24px;
  display: block;
  object-fit: cover;
}

/* 头像 */
.head_portrait {
  width: 44px;
  height: 44px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.main {
  .imgs1 {
    width: 85px;
    height: 85px;
    display: block;
    object-fit: cover;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 22px;
  }
  .pay_part {
    width: 238px;
    height: 75px;
    padding: 16px 0;
    border-radius: 10px;
    border: 1px solid #979797;
    .icon {
      width: 137px;
      height: 41px;
      display: block;
      margin: 0 auto;
    }
  }
  .btn_buy,
  .qx_buy {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #a074fb;
    border-radius: 28px;
    margin-left: 40px;
  }
  .qx_buy {
    background: #fff;
    line-height: 53px;
    border: 1px solid #e4e4e4;
  }
}
.foot_code {
  width: 200px;
  height: 200px;
  display: block;
  object-fit: cover;
  margin: 15% auto 30px;
}
.pl55 {
  padding-left: 55px;
}
</style>
