<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="left ">
        <div class="" v-for="(item,index) in list">
          <router-link class="flex-box mb5" :to="{ path:'/productList?category_id='+item.id }" >
            <img class="icon" :src="item.image_text" alt="" />
            <div class="fs18 flex-grow-1  title pointer">{{item.name}}</div>
          </router-link>
          <div class="mb20 flex flex-warp  ">
            <router-link
                class="fs14 col3 lh30 mr10 pointer plans"
                v-for="(item1, index1) in item.childrens"

                :to="{path:'/productList?second_id='+item1.id}"
                :key="index1"
            >
              {{ item.name }}
            </router-link>
          </div>


        </div>

      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {apiGetCategory} from "../request/api";

export default {
  data() {
    return {
      list: [
        "steam充值",
        "腾讯游戏",
        "网易游戏",
        "荣耀先锋",
        "地下城勇士",
        "其他热门",
      ],

    };
  },
  created() {
    this.init_Data()
  },
  mounted() {
    this.startInterval();
  },
  watch: {
    $route(to, from) {
      this.init_Data()
    }
  },
  methods: {

    init_Data(){
       this.getList()
    },
    getList(){
      var that =this;
      apiGetCategory({

      }).then(res => {
        // 获取数据成功后的其他操作

        that.list=res.data;

      }).catch(err=>{

      })
    },

    //轮播图
    //开启定时器
    startInterval() {
      // 事件里定时器应该先清除在设置，防止多次点击直接生成多个定时器
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex > this.imgArr.length - 1) {
          this.currentIndex = 0;
        }
      }, 3000);
    },
    //鼠标移入移出控制
    changeInterval(val) {
      if (val) {
        clearInterval(this.timer);
      } else {
        this.startInterval();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/style.css";
.content{
  padding: 30px 117px 23px 117px;
  // background: #F8F8F8;
}
 .left {
width: 100%;
margin: 0 auto ;
background: #FFFFFF;
box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.11);
border-radius: 10px;
padding: 25px;
  }
  .plans:hover {
      color: #ff690d;
    }

  .icon {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: cover;
      margin-right: 7px;
    }
  .title {
      color: #ff690d;
      line-height: 25px;
      font-weight: bold;
    }
  .btn {
      // width: 208px;
      text-align: center;
      height: 44px;
      line-height: 42px;
      border-radius: 22px;
      border: 1px solid #ff7210;
      color: #ff7210;
      margin: 0 0 0 20px;
    }
</style>
  